import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { useInView } from "react-intersection-observer"
import { ICommitteeMember } from "../../libs/interfaces"

function CommitteeMember({ member }: { member: ICommitteeMember }) {
  const image = getImage(member.image)

  return (
    <div className="flex flex-col items-stretch md:col-span-6 lg:col-span-3 ">
      <div className="team flex flex-col items-center rounded-md border-gray-200 bg-white p-6 shadow-md dark:border-gray-700 dark:bg-slate-900 dark:shadow-gray-700">
        {image && (
          <div className="committeeAvatarContainer">
            <div className="committeeAvatarBg"></div>
            <GatsbyImage
              image={image}
              className="committeeAvatar h-24 w-24  shadow-md dark:shadow-gray-700"
              alt=""
            />
          </div>
        )}
        <div className="content mt-3">
          <span className="text-lg font-semibold transition-all duration-500 ease-in-out hover:text-primary-600">
            {member.first_name} {member.last_name}
          </span>
          <p className="text-slate-500 dark:text-slate-300">
            {member.description}
          </p>
        </div>
      </div>
    </div>
  )
}

export default CommitteeMember

import React from "react"
import { ICommitteeMember } from "../../libs/interfaces"
import CommitteeMemberCard from "./CommitteeMemberCard"

function CommitteeMembersContainer({
  title,
  text,
  members,
}: {
  members: ICommitteeMember[]
  title: string
  text: string
}) {
  return (
    <div className="muy-12 container md:my-12">
      <div className="grid grid-cols-1 pb-8 text-center">
        <p
          className="mx-auto max-w-xl text-slate-500 dark:text-slate-300"
          dangerouslySetInnerHTML={{ __html: text }}
        ></p>
        <h3 className="mt-6 text-2xl font-medium leading-normal md:text-3xl md:leading-normal">
          {title}
        </h3>
      </div>

      <div className="mt-8 grid grid-cols-1 gap-[30px] md:grid-cols-12 lg:grid-cols-9">
        {members.map((member: ICommitteeMember) => {
          return (
            <CommitteeMemberCard
              key={member.id}
              member={member}
            ></CommitteeMemberCard>
          )
        })}
      </div>
    </div>
  )
}

export default CommitteeMembersContainer

import { graphql } from "gatsby"
import React from "react"
import CommitteeHeader from "../components/Committee/CommitteeHeader"
import CommitteeMembersContainer from "../components/Committee/CommitteeMembersContainer"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { ICommitteeMember } from "../libs/interfaces"

function Committee({ data }: any) {
  const members: ICommitteeMember[] = [
    ...data.committeeMembers.nodes.map((node: any) => ({
      id: node.id,
      image: data.committeeMembersImg.nodes.find((el: any) => {
        return node.frontmatter.avatar == el.name
      })?.childrenImageSharp[0],
      ...node.frontmatter,
    })),
  ]

  const { title, subtitle, members_title, header_img } = data.static.frontmatter

  const members_text = data.static.html

  const header_img_data = data.staticImg.nodes.find((el: any) => {
    return header_img == el.name
  })?.childrenImageSharp[0]

  const seo = {
    metaTitle: title,
    metaDescription: subtitle,
  }

  return (
    <Layout>
      <Seo seo={seo} lang={data.locales.edges[0].node.language} />
      <CommitteeHeader
        title={title}
        subtitle={subtitle}
        header_image={header_img_data}
      />
      <CommitteeMembersContainer
        title={members_title}
        text={members_text}
        members={members}
      />
    </Layout>
  )
}

export default Committee

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    committeeMembers: allMarkdownRemark(
      filter: {
        frontmatter: {
          slug: { glob: "committee/members/*" }
          lang: { eq: $language }
        }
      }
    ) {
      nodes {
        id
        html
        frontmatter {
          lang
          slug
          first_name
          last_name
          avatar
          description
        }
      }
    }
    committeeMembersImg: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        relativePath: { glob: "assets/committee/members/*" }
      }
    ) {
      nodes {
        name
        childrenImageSharp {
          gatsbyImageData
        }
      }
    }
    static: markdownRemark(
      frontmatter: { slug: { eq: "committee/static" }, lang: { eq: $language } }
    ) {
      id
      html
      frontmatter {
        lang
        slug
        title
        subtitle
        members_title
        header_img
      }
    }
    staticImg: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        relativePath: { glob: "assets/committee/*" }
      }
    ) {
      nodes {
        name
        childrenImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`
